(function () {
    'use strict';

    angular.module('informaApp')
        .controller('LayoutCtrl', LayoutController);

    function LayoutController($scope, $rootScope, $location, $timeout,
        ConstantsSvc, AuthenticationService, AppcuesService, PermissionsRejectionTypes,
        NewFeatureService, CookieBannerService, ModalHelper, OmnitureSvc) {

        new Layout($scope, $rootScope, $location, $timeout,
            ConstantsSvc, AuthenticationService, AppcuesService, PermissionsRejectionTypes,
            NewFeatureService, CookieBannerService, ModalHelper, OmnitureSvc);
    }

    class Layout {
        constructor($scope, $rootScope, $location, $timeout,
            ConstantsSvc, AuthenticationService, AppcuesService, PermissionsRejectionTypes,
            NewFeatureService, CookieBannerService, ModalHelper, OmnitureSvc) {

            this.$scope = $scope;
            this.$rootScope = $rootScope;
            this.$location = $location;
            this.$timeout = $timeout;
            this.ConstantsSvc = ConstantsSvc;
            this.AuthenticationService = AuthenticationService;
            this.AppcuesService = AppcuesService;
            this.PermissionsRejectionTypes = PermissionsRejectionTypes;
            this.NewFeatureService = NewFeatureService;
            this.CookieBannerService = CookieBannerService;
            this.ModalHelper = ModalHelper;
            this.OmnitureSvc = OmnitureSvc;

            this.$scope.isLoadingUser = true;

            this.initialize();
        }

        initialize() {
            this.$scope.isLoggedIn = () => this.AuthenticationService.isLoggedIn();
            this.$scope.isActive = viewLocation => viewLocation === this.$location.path();
            this.$scope.routeStartsWith = viewLocation => _.startsWith(this.$location.path(), viewLocation);
            
            this.catchGlobalEvents();
            this.initializeCookieBanner();

            if (this.AuthenticationService.isLoggedIn()) {
                this.verifyToken().then((isSuccess) => {
                    this.$scope.isLoadingUser = false;
                });
            }
            this.$scope.isLoadingUser = false;
        }

        getUser() {
            return this.AuthenticationService.getUser();
        }

        initializeCookieBanner() {
            this.$timeout(() => this.CookieBannerService.initialize());
        }

        catchGlobalEvents() {
            this.$rootScope.$on(this.ConstantsSvc.EVENTS.RedirectToLogoutBy403, this.processNoPermissionEvent.bind(this));

            this.$rootScope.$on(this.ConstantsSvc.EVENTS.LoginSuccess, () => this.onLoginSuccess());
        }

        processNoPermissionEvent(event, data) {
            switch (data.rejectionType) {
                case this.PermissionsRejectionTypes.trialUser:
                    return this.showTrialUserMessage();
                case this.PermissionsRejectionTypes.admin:
                    return this.$location.path(this.ConstantsSvc.urls.table);
                default:
                    return this.logout();
            }
        }

        verifyToken() {
            // this.$scope.isLoadingUser = true;

            return this.AuthenticationService.loadUser('layout')
                .then((isSucceeded) => {
                    this.$scope.anonymous = !isSucceeded;
                    const user = isSucceeded && this.AuthenticationService.getCurrentUser();

                    this.$scope.isLoadingUser = false;
                    if (isSucceeded && user && user.hasAccess) {
                        // this._trackLogin();
                        this.initializeAppServices();                       
                        return true;
                    }

                    return false;
                })
                .catch(() => {
                    this.$scope.isLoadingUser = false;
                    this.logout();
                });
        }

        onLoginSuccess() {
            this.$scope.isLoadingUser = false;
            const user = this.$scope.isLoggedIn && this.AuthenticationService.getCurrentUser();
            if (user && user.hasAccess) {
                window.clarity("set", "Account", user.email);
                window.clarity("set", "SalesForceId", user.salesforceId);
                window.clarity("set", "SalesForceId", user.salesforceServiceId);
                window.clarity("set", "Company", user.parentServiceAccountName);
                window.clarity('consent');
            } else {
                console.log(`onLoginSuccess: user is not defined`, this.$scope.isLoggedIn, user);
            }
        }

        logout() {
            this.$scope.anonymous = false;
            this.AppcuesService.anonymous();

            this.AuthenticationService.logout();
            this.$location.path(this.ConstantsSvc.urls.login);

            this.closeModals();
        }

        closeModals() {
            $('.modal-backdrop.fade.in').remove();
            $('body').removeClass('modal-open');
        }

        showTrialUserMessage() {
            this.ModalHelper.hideModal('.modal');
            this.ModalHelper.showModal('.trial-user-message');
        }

        initializeAppServices(user) {
            this.initAppcues(user);
        }

        initAppcues(user) {
            this.AppcuesService.identify(user.id, user.email);
        }

        _trackLogin() {
            this.OmnitureSvc.trackLogin();
        }
    }
})();
